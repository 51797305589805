
import { defineComponent, inject } from "vue";
import { useI18n } from "vue-i18n";
import { AxiosStatic } from "axios";
import { notification } from "ant-design-vue";
import { useStore } from "../store";
export const AppointmentFailedComponent = defineComponent({
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const http = inject("http") as AxiosStatic;
    const store = useStore();
    const mockup = store.state.runtimeConfig.mockup;
    const getBackToPayment = async () => {
      try {
        const paymentData = await http
          .get(`/patient-portal/api/payment/redirect?appointmentId=${props.id}`)
          .then((res) => res.data);

        const form = document.createElement("form");

        form.setAttribute("method", mockup ? "get" : "post");
        form.setAttribute("action", paymentData.redirectUrl);

        for (const key in paymentData.redirectParams) {
          if (paymentData.redirectParams[key] != null) {
            const propInput = document.createElement("input");
            propInput.setAttribute("type", "hidden");
            propInput.setAttribute("name", key);
            propInput.setAttribute("value", paymentData.redirectParams[key]);
            form.appendChild(propInput);
          }
        }

        document.getElementsByTagName("body")[0].appendChild(form);
        form.submit();
      } catch {
        notification.open({
          message: t("ERROR.4239"),
          class: "error",
        });
      }
    };
    return {
      getBackToPayment,
      t,
    };
  },
});
export default AppointmentFailedComponent;
